<template>
  <el-card shadow="never" header="订单管理">
    <div class="m-b-15 flex h vc">
      <div style="width: 380px">
        <el-date-picker
          v-model="selectDate"
          type="daterange"
          range-separator="To"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          format="YYYY-MM-DD"
          value-format="YYYY-MM-DD"
          :shortcuts="shortcuts"
        />
      </div>
      <el-select placeholder="状态" v-model="selectStatus" style="width: 100px" class="m-l-15" clearable>
        <el-option :value="item.value" :label="item.label" v-for="item in statusOpts" :key="item.value"></el-option>
      </el-select>
      <el-select placeholder="是否当天注册" v-model="selectToday" style="width: 100px" class="m-l-15" clearable>
        <el-option :value="1" label="当天注册"></el-option>
        <el-option :value="0" label="非当天"></el-option>
      </el-select>
      <el-select placeholder="app" v-model="selectAppId" style="width: 200px" class="m-l-15" clearable>
        <el-option :value="app.appId" :label="app.typeStr + ' - ' + app.appName" v-for="app in apps"
                   :key="app.appId"></el-option>
      </el-select>
      <el-select placeholder="系统" v-model="searchOsName" style="width: 100px" class="m-l-15" clearable>
        <el-option :value="item" :label="item" v-for="item in osNames" :key="item"></el-option>
      </el-select>
      <el-input v-model="searchAppChannel" class="m-l-15" clearable style="width: 100px"
                placeholder="渠道号"></el-input>
      <!--      <el-input v-model="searchKWs" class="m-l-15" clearable style="width: 300px" placeholder="搜索"></el-input>-->
      <el-button type="primary" style="margin-left: 15px" @click="handleSearch">查询</el-button>
    </div>
  </el-card>
  <div class="flex tongji-container">
    <div class="tongji-item-container">
      <div class="label">已支付</div>
      <div>{{ tongjiNumFormat(tongjiYizhifu) }}</div>
    </div>
    <div class="tongji-item-container">
      <div class="label">待支付</div>
      <div>{{ tongjiNumFormat(tongjiDaizhifu) }}</div>
    </div>
    <div class="tongji-item-container">
      <div class="label">已退款</div>
      <div>{{ tongjiNumFormat(tongjiYituikuan) }}</div>
    </div>
    <div class="tongji-item-container">
      <div class="label">已取消</div>
      <div>{{ tongjiNumFormat(tongjiYiquxiao) }}</div>
    </div>
  </div>
  <PaginationView :current-page="currentPage" @current-change="handlePageChange"
                  :total="selectedCount"></PaginationView>

  <el-table :data="list" stripe border>
    <el-table-column type="index" width="60"></el-table-column>
    <el-table-column prop="user.appChannel" label="渠道">
      <template #default="{row}">
        <div>{{ channelFormat(row) }}</div>
        <div>{{ adFormat(row) }}</div>
      </template>
    </el-table-column>
    <el-table-column prop="id" label="订单号">
      <template #default="{row}">
        <div>{{ row._id }}</div>
        <div>{{ row.body }}</div>
      </template>
    </el-table-column>
    <el-table-column prop="user.name" label="用户">
      <template #default="{row}">
        <router-link v-if="row.userInfo" :to="{name:'manage_user_detail', params:{id:row.userInfo.userId}}"
                     target="_blank">
          <div style="white-space: nowrap">{{ row.userInfo.name ? row.userInfo.name: '未知'}}</div>
        </router-link>
        <div v-else>未知用户</div>
      </template>
    </el-table-column>
    <el-table-column prop="createdAt" label="注册时间" width="110" align="center">
      <template #default="{row}">
        <div v-if="row.userInfo">{{ row.userInfo.created }}</div>
      </template>
    </el-table-column>
    <el-table-column prop="createdAt" label="下单时间" width="110" align="center">
      <template #default="{row}">
        <div>{{ row.created }}</div>
      </template>
    </el-table-column>
    <el-table-column prop="userInfo.uniPlatform" label="平台" width="100">
    </el-table-column>
    <el-table-column label="客户端">
      <template #default="{row}">
        <div v-if="row.userInfo">{{ row.userInfo.appName }}</div>
        <div v-else>{{ row.appId }}</div>
        <div v-if="row.userInfo">{{ row.userInfo.deviceBrand }}</div>
      </template>
    </el-table-column>
    <el-table-column label="金额" width="60">
      <template #default="{row}">
        <div class="price">{{ row.wantFee / 100 }}</div>
      </template>
    </el-table-column>
    <el-table-column label="支付渠道">
      <template #default="{row}">
        <div>{{ payChannelFormat(row) }}</div>
        <div v-if="row.page">
          <div>来源：</div>
          <!--          <div>页面：{{ decodeURIComponent(row.pagePath || row.page) }}</div>-->
          <!--          <div>上一页：{{ (row.prevPagePath === 'empty' || !row.prevPagePath) ? '无' : decodeURIComponent(row.prevPagePath)}}</div>-->
          <div>页面：{{ row.pagePath || row.page }}</div>
          <div>上一页：{{ (row.prevPagePath === 'empty' || !row.prevPagePath) ? '无' : row.prevPagePath}}</div>
        </div>
      </template>
    </el-table-column>
    <el-table-column label="状态" width="100">
      <template #default="{row}">
        <div>{{ statusFormat(row) }}</div>
      </template>
    </el-table-column>
    <el-table-column prop="createdAt" label="操作" width="100">
      -
    </el-table-column>
  </el-table>
  <PaginationView :current-page="currentPage" @current-change="handlePageChange"
                  :total="selectedCount"></PaginationView>
</template>

<script>
  import {orderApi, orderStatisticsApi, appApi, attributionApi} from '@/api/admin.js';
  import moment from 'moment';
  import {ElMessage, ElMessageBox} from 'element-plus';

  export default {
    name: 'UserManager',
    data() {
      return {
        list: [],
        selectDate: null,
        selectStatus: null,
        searchKWs: null,
        shortcuts: [
          {
            text: '今天',
            value: () => {
              const today = moment().format('YYYYMMDD')
              return [today, today]
            }
          },
          {
            text: '昨天',
            value: () => {
              const tomorrow = moment().subtract(1, 'day').format('YYYYMMDD')
              return [tomorrow, tomorrow]
            }
          },
          {
            text: '近7天',
            value: () => {
              const start = moment().subtract(6, 'day').format('YYYYMMDD')
              const today = moment().format('YYYYMMDD')
              return [start, today]
            }
          },
          {
            text: '近30天',
            value: () => {
              const start = moment().subtract(29, 'day').format('YYYYMMDD')
              const today = moment().format('YYYYMMDD')
              return [start, today]
            }
          },
          {
            text: '本月',
            value: () => {
              let start = moment().date(1)
              start = start.format('YYYYMMDD')
              const today = moment().format('YYYYMMDD')
              return [start, today]
            }
          },
          {
            text: '本周',
            value: () => {
              let start = moment().day(1)
              start = start.format('YYYYMMDD')
              const today = moment().format('YYYYMMDD')
              return [start, today]
            }
          }
        ],
        payChannelMap: {
          1: '微信支付',
          2: '微信支付',
          3: '支付宝',
          4: '苹果支付',
          5: '抖音支付'
        },
        showForm: false,
        form: {},
        selectedCount: 0,
        currentPage: 1,
        statusOpts: [
          {
            value: 0,
            label: '待支付'
          },
          {
            value: 1,
            label: '已支付'
          },
          {
            value: 2,
            label: '已取消'
          },
          {
            value: 6,
            label: '已退款'
          }
        ],
        tongjiDaizhifu: {
          fee: 0, discount: 0
        },
        tongjiYizhifu: {
          fee: 0, discount: 0
        },
        tongjiYiquxiao: {
          fee: 0, discount: 0
        },
        tongjiYituikuan: {
          fee: 0, discount: 0
        },
        selectToday: null,
        searchAppChannel: null,
        searchOsName: null,
        selectAppId: null,
        osNames: [
          'ios',
          'android'
        ],
        apps: [],
        adSettings: [],
        channelSettings: []
      };
    },
    created() {
      const today = moment().format('YYYY-MM-DD');
      this.selectDate = [today, today];
      this.init();
    },
    methods: {
      tongjiNumFormat(item) {
        return (parseInt(item.fee)) / 100;
      },
      async init() {
        const res2 = await orderStatisticsApi.tongji({
          status: this.selectStatus,
          searchKWs: this.searchKWs,
          start: this.selectDate ? this.selectDate[0] : null,
          end: this.selectDate ? this.selectDate[1] : null,
          appId: this.selectAppId,
          appChannel: this.searchAppChannel,
          selectToday: this.selectToday,
          osName: this.searchOsName,
          ownerType: 1
        });

        this.tongjiDaizhifu = res2.data.data.list.find(item => item._id === 0) || {fee: 0, discount: 0};
        this.tongjiYizhifu = res2.data.data.list.find(item => item._id === 1) || {fee: 0, discount: 0};
        this.tongjiYiquxiao = res2.data.data.list.find(item => item._id === 2) || {fee: 0, discount: 0};
        this.tongjiYituikuan = res2.data.data.list.find(item => item._id === 6) || {fee: 0, discount: 0};
        const res = await orderApi.list({
          index: this.currentPage - 1,
          limit: 20,
          page: this.currentPage,
          status: this.selectStatus,
          searchKWs: this.searchKWs,
          start: this.selectDate ? this.selectDate[0] : null,
          end: this.selectDate ? this.selectDate[1] : null,
          appId: this.selectAppId,
          appChannel: this.searchAppChannel,
          selectToday: this.selectToday,
          osName: this.searchOsName,
          ownerType: 1
        });
        this.list = res.data.data.list;
        this.selectedCount = res.data.data.count;

        const resApp = await appApi.list({ownerType: 1});
        this.apps = resApp.data.data.list;

        const res3 = await attributionApi.adSettings();
        this.adSettings = res3.data.data.list;
        const res4 = await attributionApi.channels();
        this.channelSettings = res4.data.data.list;
      },
      handlePageChange(page) {
        this.currentPage = page;
        this.init();
      },
      handleSearch() {
        this.currentPage = 1;
        this.init();
      },
      dateFormat(time) {
        return moment(time).format('YYYY-MM-DD HH:mm:ss');
      },
      adFormat(row) {
        if (row.attributionAppData) {
          const found = this.adSettings.find(adSetting => adSetting.adId === row.attributionAppData.adId);
          if (found) {
            return found.name;
          }
        }
      },
      channelFormat(row) {
        if (row.attributionAppData) {
          const found = this.channelSettings.find(adSetting => adSetting.appChannel === row.attributionAppData.appChannel);
          if (found) {
            return found.name;
          }
        }
      },
      payChannelFormat(item) {
        if (item.channel === 5) {
          if (item.way === 1) {
            return '微信';
          } else if (item.way === 2) {
            return '支付宝';
          } else if (item.way === 10) {
            return '抖音支付';
          } else {
            if (item.way) {
              return item.way;
            }
          }
        }

        return this.payChannelMap[item.channel];
      },
      statusFormat({status}) {
        return this.statusOpts.find(item => item.value === status).label;
      },
      async remove(d) {
        const user = d.user ? d.user.nameStr : '不存在的用户';
        await ElMessageBox.alert('确认删除：' + user + ' ' + d.body, '删除', {
          confirmButtonText: '确认',
          callback: async (action) => {
            if (action === 'confirm') {
              await orderApi.remove(d.id);
              await this.init();
              ElMessage({
                type: 'success',
                message: '删除成功'
              });
            }
          }
        });
      }
    }
  };
</script>

<style scoped lang="less">
  .tongji-container {
    margin-top: 15px;
    background-color: #fff;

    .tongji-item-container {
      .label {
        margin-bottom: 10px;
      }

      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px;
      font-size: 18px;
    }
  }
</style>
