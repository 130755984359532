<template>
  <div style="height: 100%;display: flex;flex-direction: column">
    <div class="flex m-b-15 h vc">
      <div style="font-size: 16px;font-weight: bold" class="m-r-15">android归因数据</div>
      <div>共计：{{ list.length }} 个</div>
    </div>
    <div class="flex m-b-15 h vc">
      <div style="width: 380px">
        <el-date-picker
          v-model="selectDate"
          type="daterange"
          range-separator="To"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          format="YYYY-MM-DD"
          value-format="YYYYMMDD"
          :shortcuts="shortcuts"
        />
      </div>
      <el-select placeholder="选择App" v-model="selectedAppId" style="width: 150px" class="m-r-15">
        <el-option :value="app.appId" :label="app.appName" v-for="app in apps" :key="app.appId"></el-option>
      </el-select>
      <el-checkbox-group v-model="appChannelSelect" class="m-r-15" v-if="channelSettings.length">
        <el-checkbox-button v-for="channelSetting in channelSettings" :key="channelSetting.appChannel"
                            :label="channelSetting.appChannel">
          {{ channelSetting.channel.name }}
        </el-checkbox-button>
      </el-checkbox-group>
      <div v-else class="m-r-15">没有开通投放</div>
      <el-cascader v-model="selectAdId" placeholder="选择创意" :options="adFilters" clearable/>
      <el-button type="primary" class="m-l-15" @click="refresh">刷新</el-button>
      <div class="f1"></div>
    </div>
    <div class="flex m-b-15 h vc">
      <el-select placeholder="广告ID" v-model="filterZero" style="width: 190px">
        <el-option :value="1" label="过滤：0消耗&&0收入"></el-option>
        <el-option :value="0" label="不过滤：0消耗&&0收入"></el-option>
      </el-select>
      <el-select placeholder="当日合计" v-model="showDaySum" style="width: 150px" class="m-l-15">
        <el-option :value="1" label="显示当日合计"></el-option>
        <el-option :value="0" label="隐藏当日合计"></el-option>
      </el-select>
      <el-checkbox-group v-model="tableShowFields" class="m-l-15">
        <el-checkbox-button v-for="tableField in tableFields" :key="tableField.prop"
                            :label="tableField.prop">
          {{ tableField.label }}
        </el-checkbox-button>
      </el-checkbox-group>
      <div class="f1"></div>
    </div>
    <div style="flex: 1;overflow: hidden">
      <el-table :data="list" :row-class-name="tableRowClassName" :span-method="objectSpanMethod"
                :cell-class-name="cellClassName"
                highlight-current-row
                :header-cell-style="{textAlign:'center'}"
                height="100%"
                @cell-click="onCellClick"
                border>
        <el-table-column label="日期" prop="dateStr" width="100" fixed></el-table-column>
        <el-table-column label="渠道" prop="appChannelStr" width="80" fixed></el-table-column>
        <el-table-column label="广告" prop="adIdStr" v-if="tableShowFieldsMap['detail']" width="200" fixed></el-table-column>
        <el-table-column label="广告ID" prop="adId" v-if="tableShowFieldsMap['adId']" width="100" fixed></el-table-column>
        <el-table-column label="消耗" prop="cost" width="90" align="right" fixed
                         :formatter="moneyFormat"></el-table-column>
        <el-table-column label="回收" align="center" v-if="tableShowFieldsMap['totalRecycle']">
          <el-table-column label="金额" prop="recycleOrderAmount" width="80" align="right"
                           :formatter="moneyFormat">
          </el-table-column>
          <el-table-column label="毛利" prop="profit" width="80" align="right"
                           :formatter="moneyFormat"></el-table-column>
          <el-table-column label="ROI" prop="roi_d0+" width="70" align="right">
          </el-table-column>
          <el-table-column label="单数" prop="recycleOrderNum" width="60"
                           align="right"></el-table-column>
        </el-table-column>
        <el-table-column label="首日回收" align="center" v-if="tableShowFieldsMap['firstRecycle']">
          <el-table-column label="金额" prop="todayRecycleOrderAmount" width="80" align="right"
                           :formatter="moneyFormat"></el-table-column>
          <el-table-column label="毛利" prop="profit_d0" width="90" align="right"
                           :formatter="moneyFormat"></el-table-column>
          <el-table-column label="ROI" prop="roi_d0" width="70" align="right" >
          </el-table-column>
          <el-table-column label="单数" prop="todayRecycleOrderNum" width="60"
                           align="right"></el-table-column>
        </el-table-column>
        <el-table-column label="长尾回收" align="center" v-if="tableShowFieldsMap['endRecycle']">
          <el-table-column label="金额" prop="noTodayRecycleOrderAmount" width="80" align="right"
                           :formatter="moneyFormat"></el-table-column>
          <el-table-column label="单数" prop="noTodayRecycleOrderNum" width="60"
                           align="right"></el-table-column>
        </el-table-column>
        <el-table-column label="营收" align="center" v-if="tableShowFieldsMap['income']">
          <el-table-column label="金额" prop="orderAmount" width="80" align="right"
                           :formatter="moneyFormat"></el-table-column>
          <el-table-column label="毛利" prop="income_profit_d0" width="90" align="right"
                           :formatter="moneyFormat"></el-table-column>
          <el-table-column label="ROI" prop="income_roi_d0" width="70"
                           align="right"></el-table-column>
          <el-table-column label="单数" prop="orderNum" width="60"
                           align="right"></el-table-column>
        </el-table-column>
        <el-table-column label="下载" prop="downloadCount" width="70" align="right"></el-table-column>
        <el-table-column label="激活" prop="activeNum" width="70" align="right"></el-table-column>
        <el-table-column label="激活成本" prop="activeCost" width="70" align="right"></el-table-column>
        <el-table-column label="注册" prop="registerNum" width="70" align="right"></el-table-column>
        <el-table-column label="注册成本" prop="registerCost" width="70" align="right"></el-table-column>
        <el-table-column label="留存" prop="retentionNum" width="70" align="right"></el-table-column>
        <el-table-column label="留存成本" prop="retentionCost" width="70" align="right"></el-table-column>
        <el-table-column label="激活-注册率" prop="registerRate" width="70" align="right"></el-table-column>
        <el-table-column label="激活-留存率" prop="retentionRate" width="70" align="right"></el-table-column>
        <el-table-column label="注册-支付率" prop="registerPayRate" width="70" align="right"></el-table-column>
<!--        <el-table-column label="留存-支付率" prop="retentionPayRate" width="70" align="right"></el-table-column>-->
      </el-table>
    </div>
  </div>
  <el-dialog
    :title="showOrder"
    draggable
    width="100%"
    v-model="showOrder">
    <el-table :data="orders" stripe border max-height="500px" show-summary :summary-method="getOrderSummaries">
      <el-table-column type="index" width="60"></el-table-column>
      <el-table-column prop="user.appChannel" label="渠道">
        <template #default="{row}">
          <div>{{ channelFormat(row) }}</div>
          <div>{{ adFormat(row) }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="id" label="订单号">
        <template #default="{row}">
          <div>{{ row.id }}</div>
          <div>{{ row.body }}</div>
          <div>{{ row.userId + '#' + row.appId }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="user.name" label="用户">
        <template #default="{row}">
          <router-link v-if="row.userInfo" :to="{name:'manage_user_detail', params:{id:row.userInfo.userId}}" target="_blank">
            <div style="white-space: nowrap">{{ row.userInfo.name ? row.userInfo.name: '未知'}}</div>
          </router-link>
          <div v-else>未知用户</div>
        </template>
      </el-table-column>
      <el-table-column label="支付金额">
        <template #default="{row}">
          <div class="price">{{ row.wantFee / 100 }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="createdAt" label="是否支付" width="110" align="center">
        <template #default="{row}">
          <div>{{ row.payAt ? dateFormat(row.payAt) : '' }}</div>
        </template>
      </el-table-column>
      <el-table-column label="支付渠道" width="90">
        <template #default="{row}">
          <div>{{ payChannelFormat(row) }}</div>
          <div v-if="row.page">
            <div>来源：</div>
            <div>页面：{{ decodeURIComponent(row.pagePath || row.page) }}</div>
            <div>
              上一页：{{
              (row.prevPagePath === 'empty' || !row.prevPagePath) ? '无' : decodeURIComponent(row.prevPagePath)
              }}
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="状态" width="100">
        <template #default="{row}">
          <div>{{ statusFormat(row) }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="createdAt" label="注册时间" width="110" align="center">
        <template #default="{row}">
          <div v-if="row.userInfo">{{ row.userInfo.created }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="createdAt" label="下单时间" width="110" align="center">
        <template #default="{row}">
          <div>{{ row.created }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="createdAt" label="操作" width="100">
        -
<!--        <template #default="{row}">-->
<!--          <div>-->
<!--            <el-button class="button" type="danger" size="small" @click="remove(row)">删除</el-button>-->
<!--          </div>-->
<!--        </template>-->
      </el-table-column>
    </el-table>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="showOrder = false">取消</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { appApi, attributionADSettingApi, attributionApi, attributionChannelSettingApi, orderApi } from '@/api/admin'
import { ElLoading, ElMessage, ElMessageBox } from 'element-plus'
import moment from 'moment/moment'
import * as echarts from 'echarts'

export default {
  name: 'SettingListScreen',
  data () {
    return {
      showOrder: false,
      showType: 1,
      showD0AllType: 1,
      filterZero: 1,
      showD0Type: 1,
      showD0IncomeType: 1,
      showDaySum: 1,
      showADID: 0,
      updateForm: null,
      showCostForm: null,
      searchedSelectAdid: null,
      form: null,
      dataMap: null,
      today: null,
      selectedAppId: null,
      accounts: [],
      apps: [],
      products: [],
      selectAdId: null,
      appChannelSelect: [],
      appChannels: [],
      searchedAppChannelSelect: [],
      channelSettings: [],
      adSettings: [],
      orders: [],
      selectDate: null,
      tableShowFields: [
        'detail',
        'totalRecycle',
        'firstRecycle',
        'income'
      ],
      tableFields: [
        {
          prop: 'detail',
          label: '详细'
        },
        {
          prop: 'adId',
          label: '广告id'
        },
        {
          prop: 'totalRecycle',
          label: '回收'
        },
        {
          prop: 'firstRecycle',
          label: '首日回收'
        },
        {
          prop: 'endRecycle',
          label: '长尾回收'
        },
        {
          prop: 'income',
          label: '营收'
        }
      ],
      statusOpts: [
        {
          value: 0,
          label: '待支付'
        },
        {
          value: 1,
          label: '已支付'
        },
        {
          value: 2,
          label: '已取消'
        },
        {
          value: 6,
          label: '已退款'
        }
      ],
      payChannelMap: {
        1: '微信支付',
        2: '微信支付',
        3: '支付宝',
        4: '苹果支付',
        5: '抖音支付',
        100: '激活码支付',
        101: '测试支付'
      },
      shortcuts: [
        {
          text: '今天',
          value: () => {
            const today = moment().format('YYYYMMDD')
            return [today, today]
          }
        },
        {
          text: '昨天',
          value: () => {
            const tomorrow = moment().subtract(1, 'day').format('YYYYMMDD')
            return [tomorrow, tomorrow]
          }
        },
        {
          text: '近7天',
          value: () => {
            const start = moment().subtract(6, 'day').format('YYYYMMDD')
            const today = moment().format('YYYYMMDD')
            return [start, today]
          }
        },
        {
          text: '近30天',
          value: () => {
            const start = moment().subtract(29, 'day').format('YYYYMMDD')
            const today = moment().format('YYYYMMDD')
            return [start, today]
          }
        },
        {
          text: '本月',
          value: () => {
            let start = moment().date(1)
            start = start.format('YYYYMMDD')
            const today = moment().format('YYYYMMDD')
            return [start, today]
          }
        },
        {
          text: '本周',
          value: () => {
            let start = moment().day(1)
            start = start.format('YYYYMMDD')
            const today = moment().format('YYYYMMDD')
            return [start, today]
          }
        }
      ]
    }
  },
  computed: {
    tableShowFieldsMap () {
      const map = {}
      this.tableShowFields.map(key => {
        map[key] = 1
      })
      return map
    },
    adFilters () {
      const list = []
      this.channelSettings.map(item => {
        const children = []
        list.push({
          label: item.channel.name,
          value: item.appChannel,
          children
        })

        this.adSettings.map(ad => {
          if (ad.appChannel === item.appChannel) {
            children.push({
              value: ad.adId,
              label: ad.name
            })
          }
        })
      })
      return list
    },
    appChannelFilters () {
      const list = []
      this.channelSettings.map(item => {
        list.push({
          text: item.name,
          value: item.appChannel
        })
      })
      return list
    },
    list () {
      let list = []
      if (!this.dataMap) {
        return list
      }

      const fields = ['cost', 'profit', 'income_profit_d0', 'profit_d0', 'downloadCount', 'showCount', 'registerNum', 'activeNum', 'retentionNum',
        'orderAmount', 'orderNum', 'noTodayRecycleOrderAmount', 'noTodayRecycleOrderNum', 'repairOrderNum', 'repairOrderAmount',
        'recycleOrderAmount', 'recycleOrderNum',
        'todayRecycleOrderAmount', 'todayRecycleOrderNum']

      const daySumList = []
      Object.keys(this.dataMap).sort((b, a) => parseInt(a) - parseInt(b)).map(date => {
        const appChannelMap = this.dataMap[date]

        let dateShowList = []
        let dateAppChannelList = []
        Object.keys(appChannelMap).sort().map(appChannel => {
          const items = appChannelMap[appChannel]

          const appChannelList = []
          items.sort((a, b) => a.adId - b.adId).map((item, i) => {
            if (this.filterZero && !item.cost && !item.recycleOrderAmount && !item.todayRecycleOrderAmount && !item.orderAmount) {
              // 过滤
            } else {
              appChannelList.push(item)
            }
          })

          const channelItem = {
            date: parseInt(date),
            dateStr: date,
            adIdStr: '渠道合计：',
            appChannel: items[0].appChannel,
            appChannelStr: items[0].appChannelStr,
            rowType: 1 // 渠道合计
          }
          fields.map(field => {
            if (!channelItem[field]) {
              channelItem[field] = 0
            }
          })
          appChannelList.map(appChannelListItem => {
            fields.map(field => {
              channelItem[field] += appChannelListItem[field]
            })
          })

          channelItem.activeCost = 0
          channelItem.registerCost = 0
          channelItem.retentionCost = 0
          if (channelItem.cost) {
            if (channelItem.activeNum) {
              channelItem.activeCost = (channelItem.cost / channelItem.activeNum / 100).toFixed(2)
            }
            if (channelItem.registerNum) {
              channelItem.registerCost = (channelItem.cost / channelItem.registerNum / 100).toFixed(2)
            }
            if (channelItem.retentionNum) {
              channelItem.retentionCost = (channelItem.cost / channelItem.retentionNum / 100).toFixed(2)
            }
          }

          if (channelItem.activeNum) {
            channelItem.registerRate = Math.round(channelItem.registerNum / channelItem.activeNum * 1000) / 10 + '%'
          } else {
            channelItem.registerRate = '0%'
          }
          if (channelItem.retentionNum) {
            channelItem.retentionRate = Math.round(channelItem.retentionNum / channelItem.activeNum * 1000) / 10 + '%'
          } else {
            channelItem.retentionRate = '0%'
          }
          if (channelItem.registerNum) {
            channelItem.registerPayRate = Math.round(channelItem.recycleOrderNum / channelItem.registerNum * 1000) / 10 + '%'
          } else {
            channelItem.registerPayRate = '0%'
          }
          if (channelItem.retentionNum) {
            channelItem.retentionPayRate = Math.round(channelItem.recycleOrderNum / channelItem.retentionNum * 1000) / 10 + '%'
          } else {
            channelItem.retentionPayRate = '0%'
          }
          if (channelItem.cost) {
            channelItem['roi_d0+'] = (channelItem.recycleOrderAmount / channelItem.cost).toFixed(2)
            channelItem.roi_d0 = (channelItem.todayRecycleOrderAmount / channelItem.cost).toFixed(2)
            channelItem.income_roi_d0 = (channelItem.orderAmount / channelItem.cost).toFixed(2)
          } else {
            channelItem['roi_d0+'] = '-'
            channelItem.roi_d0 = '-'
            channelItem.income_roi_d0 = '-'
          }
          appChannelList.map((appChannelItem, i) => {
            if (i === 0) {
              appChannelItem.channelRowspan = {
                rowspan: appChannelList.length,
                colspan: 1
              }
            } else {
              appChannelItem.channelRowspan = {
                rowspan: 0,
                colspan: 0
              }
            }
          })

          dateAppChannelList = dateAppChannelList.concat(appChannelList)
          if (this.tableShowFieldsMap.detail) {
            dateShowList = dateShowList.concat(appChannelList)
          } else {
            dateShowList = dateShowList.concat([])
          }
          if (!this.searchedSelectAdid) {
            dateShowList.push(channelItem)
          }
        })

        dateShowList.map((item, i) => {
          if (i === 0) {
            item.dateRowspan = {
              rowspan: dateShowList.length,
              colspan: 1
            }
          } else {
            item.dateRowspan = {
              rowspan: 0,
              colspan: 0
            }
          }
        })

        const dateItem = {
          dateStr: '当日合计：',
          date,
          adIdStr: '',
          rowType: 2 // 日合计
        }
        fields.map(field => {
          if (!dateItem[field]) {
            dateItem[field] = 0
          }
        })

        dateAppChannelList.map(dateListItem => {
          fields.map(field => {
            dateItem[field] += dateListItem[field]
          })
        })

        // console.log('dateList', dateShowList)

        dateItem.activeCost = 0
        dateItem.registerCost = 0
        dateItem.retentionCost = 0
        if (dateItem.cost) {
          if (dateItem.activeNum) {
            dateItem.activeCost = (dateItem.cost / dateItem.activeNum / 100).toFixed(2)
          }
          if (dateItem.registerNum) {
            dateItem.registerCost = (dateItem.cost / dateItem.registerNum / 100).toFixed(2)
          }
          if (dateItem.retentionNum) {
            dateItem.retentionCost = (dateItem.cost / dateItem.retentionNum / 100).toFixed(2)
          }
        }

        if (dateItem.activeNum) {
          dateItem.registerRate = Math.round(dateItem.registerNum / dateItem.activeNum * 1000) / 10 + '%'
        } else {
          dateItem.registerRate = '0%'
        }
        if (dateItem.retentionNum) {
          dateItem.retentionRate = Math.round(dateItem.retentionNum / dateItem.activeNum * 1000) / 10 + '%'
        } else {
          dateItem.retentionRate = '0%'
        }
        if (dateItem.registerNum) {
          dateItem.registerPayRate = Math.round(dateItem.recycleOrderNum / dateItem.registerNum * 1000) / 10 + '%'
        } else {
          dateItem.registerPayRate = '0%'
        }
        if (dateItem.retentionNum) {
          dateItem.retentionPayRate = Math.round(dateItem.recycleOrderNum / dateItem.retentionNum * 1000) / 10 + '%'
        } else {
          dateItem.retentionrPayRate = '0%'
        }
        if (dateItem.cost) {
          dateItem['roi_d0+'] = (dateItem.recycleOrderAmount / dateItem.cost).toFixed(2)
          dateItem.roi_d0 = (dateItem.todayRecycleOrderAmount / dateItem.cost).toFixed(2)
          dateItem.income_roi_d0 = (dateItem.orderAmount / dateItem.cost).toFixed(2)
        } else {
          dateItem['roi_d0+'] = '-'
          dateItem.roi_d0 = '-'
          dateItem.income_roi_d0 = '-'
        }

        if (this.showDaySum && this.searchedAppChannelSelect.length > 1 && !this.searchedSelectAdid) {
          dateShowList.push(dateItem)
        }
        daySumList.push(dateItem)
        list = list.concat(dateShowList)
      })
      const totalItem = {
        date: '',
        dateStr: '总计：',
        adIdStr: '',
        rowType: 3 // 总合计
      }
      fields.map(field => {
        if (!totalItem[field]) {
          totalItem[field] = 0
        }
      })
      daySumList.map(dateListItem => {
        if (dateListItem.rowType === 2) {
          fields.map(field => {
            totalItem[field] += dateListItem[field]
          })
        }
      })
      // console.log('daySumList', daySumList)

      totalItem.activeCost = 0
      totalItem.registerCost = 0
      totalItem.retentionCost = 0
      if (totalItem.cost) {
        if (totalItem.activeNum) {
          totalItem.activeCost = (totalItem.cost / totalItem.activeNum / 100).toFixed(2)
        }
        if (totalItem.registerNum) {
          totalItem.registerCost = (totalItem.cost / totalItem.registerNum / 100).toFixed(2)
        }
        if (totalItem.retentionNum) {
          totalItem.retentionCost = (totalItem.cost / totalItem.retentionNum / 100).toFixed(2)
        }
      }

      if (totalItem.activeNum) {
        totalItem.registerRate = Math.round(totalItem.registerNum / totalItem.activeNum * 1000) / 10 + '%'
      } else {
        totalItem.registerRate = '0%'
      }
      if (totalItem.retentionNum) {
        totalItem.retentionRate = Math.round(totalItem.retentionNum / totalItem.activeNum * 1000) / 10 + '%'
      } else {
        totalItem.retentionRate = '0%'
      }
      if (totalItem.registerNum) {
        totalItem.registerPayRate = Math.round(totalItem.recycleOrderNum / totalItem.registerNum * 1000) / 10 + '%'
      } else {
        totalItem.registerPayRate = '0%'
      }
      if (totalItem.retentionNum) {
        totalItem.retentionPayRate = Math.round(totalItem.recycleOrderNum / totalItem.retentionNum * 1000) / 10 + '%'
      } else {
        totalItem.retentionPayRate = '0%'
      }
      if (totalItem.cost) {
        totalItem['roi_d0+'] = (totalItem.recycleOrderAmount / totalItem.cost).toFixed(2)
        totalItem.roi_d0 = (totalItem.todayRecycleOrderAmount / totalItem.cost).toFixed(2)
        totalItem.income_roi_d0 = (totalItem.orderAmount / totalItem.cost).toFixed(2)
      } else {
        totalItem['roi_d0+'] = '-'
        totalItem.roi_d0 = '-'
        totalItem.income_roi_d0 = '-'
      }
      list.splice(0, 0, totalItem)
      return list
    }
  },
  watch: {
    'selectedAppId' (newVal) {
      console.log('changed', newVal)
      if (newVal) {
        this.changeAppId()
      }
    }
  },
  async created () {
    const res1 = await appApi.list({
      no_page: 1,
      attribution: 1
    })
    this.apps = res1.data.data.list
    this.selectedAppId = this.apps[0].appId
    this.changeAppId()
  },
  methods: {
    changeAppId () {
      const start = moment().subtract(6, 'day').format('YYYYMMDD')
      this.today = moment().format('YYYYMMDD')
      this.selectDate = [start, this.today]
      this.init()
    },
    priceFormat (num) {
      if (num) {
        return num / 1000
      }
      return 0
    },
    statusFormat ({ status }) {
      return this.statusOpts.find(item => item.value === status).label
    },
    payChannelFormat (item) {
      if (item.channel === 5) {
        if (item.way === 1) {
          return '微信'
        } else if (item.way === 2) {
          return '支付宝'
        } else if (item.way === 10) {
          return '抖音支付'
        } else {
          if (item.way) {
            return item.way
          }
        }
      }

      return this.payChannelMap[item.channel]
    },
    dateFormat (time) {
      return moment(time).format('YYYY-MM-DD HH:mm:ss')
    },
    async onCellClick (row, column, cell, event) {
      const appId = this.selectedAppId

      if (column.property === 'recycleOrderAmount' || column.property === 'todayRecycleOrderAmount' || column.property === 'orderAmount') {
        // console.log(row, column.property, cell, event)
        this._loading = ElLoading.service({
          lock: true,
          text: 'Loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        let appChannelSelect

        if (row.appChannel) {
          appChannelSelect = [row.appChannel]
        } else {
          appChannelSelect = this.appChannelSelect
        }

        const res = await attributionApi.androidOrders(row.adId, appChannelSelect, row.date, column.property, appId)
        this.orders = res.data.data.list
        this.showOrder = column.label
        this._loading.close()
      } else if (column.property === 'activeNum') {
        const routeData = this.$router.resolve({
          name: 'manage_user_list',
          query: {
            adId: row.adId,
            appChannel: row.appChannel,
            date: row.date,
            appId
          }
        })
        window.open(routeData.href, '_blank')
      } else if (column.property === 'registerNum') {
        const routeData = this.$router.resolve({
          name: 'manage_user_list',
          query: {
            adId: row.adId,
            appChannel: row.appChannel,
            date: row.date,
            appId,
            register: 1
          }
        })
        window.open(routeData.href, '_blank')
      }
    },
    cellClassName ({ row, column }) {
      // console.log('column.property ', column.property, row.adId, row.date, row.updatedAt)
      let returnClass = ''

      if (row.adId && row.adId !== '0' && row.date === parseInt(this.today) && row.updatedAt && row.appChannel === 'UMENG_CHANNEL_1002m') {
        if (Date.now() - moment(row.syncTime).valueOf() > 60 * 1000 * 10) {
          returnClass += ' todayWarn'
        }
      }

      if (column.property.indexOf('rderAmount') > -1 || column.property === 'activeNum' || column.property === 'registerNum') {
        returnClass += ' canClick'
      } else if (column.property.indexOf('profit') > -1) {
        if (row[column.property] > 0) {
          returnClass += ' red'
        }
        if (row[column.property] < 0) {
          returnClass += ' green'
        }
      }

      return returnClass
    },
    getOrderSummaries ({ columns, data }) {
      // console.log(columns, data)
      let total = 0
      data.map(item => {
        total += item.payFee
      })
      return ['', '', '', '', '金额：' + total / 100, '数量：' + data.length]
    },
    async init () {
      const appId = this.selectedAppId
      const res3 = await attributionADSettingApi.list({
        appId,
        no_page: 1
      })
      this.adSettings = res3.data.data.list.sort((b, a) => a.id - b.id)
      const res4 = await attributionChannelSettingApi.list({
        attributionStatus: 1,
        appId,
        no_page: 1
      })
      this.channelSettings = res4.data.data.list
      const appChannelSelect = []
      this.channelSettings.map(item => {
        if (item.attributionDefaultShow) {
          appChannelSelect.push(item.appChannel)
        }
      })
      this.appChannelSelect = appChannelSelect
      const res5 = await attributionApi.channels({
        appId
      })
      this.appChannels = res5.data.data.list
      await this.refresh()
    },
    async refresh () {
      this._loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      try {
        const appId = this.selectedAppId

        const res = await attributionApi.androidDates({
          no_page: 1,
          appId,
          start: this.selectDate ? this.selectDate[0] : null,
          end: this.selectDate ? this.selectDate[1] : null,
          appChannels: JSON.stringify(this.appChannelSelect),
          adId: this.selectAdId ? this.selectAdId[1] : null
        })

        this.searchedAppChannelSelect = this.appChannelSelect
        this.searchedSelectAdid = this.selectAdId ? this.selectAdId[1] : null
        const map = {}
        res.data.data.list.map(item => {
          // console.log(item.recycleOrderAmount, item.cost)
          if (item.cost) {
            item['roi_d0+'] = (item.recycleOrderAmount / item.cost).toFixed(2)
            item.roi_d0 = (item.todayRecycleOrderAmount / item.cost).toFixed(2)
          } else {
            item['roi_d0+'] = '-'
            item.roi_d0 = '-'
          }

          item.profit_d0 = item.todayRecycleOrderAmount - item.cost
          item.income_profit_d0 = item.orderAmount - item.cost
          if (item.activeNum) {
            item.registerRate = Math.round(item.registerNum / item.activeNum * 1000) / 10 + '%'
          } else {
            item.registerRate = '0%'
          }

          if (item.retentionNum) {
            item.retentionRate = Math.round(item.retentionNum / item.activeNum * 1000) / 10 + '%'
          } else {
            item.retentionRate = '0%'
          }

          item.activeCost = 0
          item.registerCost = 0
          item.retentionCost = 0
          if (item.cost) {
            if (item.activeNum) {
              item.activeCost = (item.cost / item.activeNum / 100).toFixed(2)
            }
            if (item.registerNum) {
              item.registerCost = (item.cost / item.registerNum / 100).toFixed(2)
            }
            if (item.retentionNum) {
              item.retentionCost = (item.cost / item.retentionNum / 100).toFixed(2)
            }
          }

          if (item.registerNum) {
            item.registerPayRate = Math.round(item.recycleOrderNum / item.registerNum * 1000) / 10 + '%'
          } else {
            item.registerPayRate = '0%'
          }

          if (item.retentionNum) {
            item.retentionPayRate = Math.round(item.recycleOrderNum / item.retentionNum * 1000) / 10 + '%'
          } else {
            item.retentionPayRate = '0%'
          }

          if (!map[item.date]) {
            map[item.date] = {}
          }
          if (!map[item.date][item.appChannel]) {
            map[item.date][item.appChannel] = []
          }

          item.dateStr = item.date
          map[item.date][item.appChannel].push(item)
        })
        // console.log(map)
        this.dataMap = map
      } catch (e) {

      }
      this._loading.close()
    },
    tableRowClassName ({ row, rowIndex }) {
      // console.log('tableRowClassName', row.date)
      if (row.rowType === 1 && this.tableShowFieldsMap.detail) {
        return 'success-row'
      }
      if (row.rowType === 2) {
        return 'dangri-heiji-row'
      }
      if (row.rowType === 3) {
        return 'zongji-row'
      }
      return ''
    },
    moneyFormat (a, b) {
      // console.log('moneyFormat', b.property, a[b.property])
      if (a[b.property]) {
        return a[b.property] / 100
      }
      return '-'
    },
    async remove (d) {
      const user = d.userInfo ? d.userInfo.name : '未知'
      await ElMessageBox.alert('确认删除：' + user + ' ' + d.body, '删除', {
        confirmButtonText: '确认',
        callback: async (action) => {
          if (action === 'confirm') {
            await orderApi.remove(d.id)
            await this.init()
            ElMessage({
              type: 'success',
              message: '删除成功'
            })
          }
        }
      })
    },
    objectSpanMethod ({
      row,
      column,
      rowIndex,
      columnIndex
    }) {
      if (columnIndex === 0) {
        return row.dateRowspan
      } else if (columnIndex === 1) {
        return row.channelRowspan
      }
    },
    adFormat (row) {
      const data = row.attributionAppData || row
      const found = this.adSettings.find(adSetting => adSetting.adId === data.adId)
      if (found) {
        return found.name
      }
    },
    channelFormat (row) {
      const data = row.attributionAppData || row
      const found = this.appChannels.find(adSetting => adSetting.appChannel === data.appChannel)
      if (found) {
        return found.name
      }
    }
  }
}
</script>

<style lang="less">
#basic-layout-root .basic-layout-body-container {
  bottom: 0;

  #basic-layout-main-root {
    height: 100%;
  }
}

.el-table .zongji-row {
  --el-table-tr-bg-color: var(--el-color-primary-light-9);
}

.el-table .dangri-heiji-row {
  --el-table-tr-bg-color: var(--el-color-warning-light-9);
}

.el-table .success-row {
  --el-table-tr-bg-color: var(--el-color-success-light-9);
}

#payEchart {
  height: 100px;
}

.el-table {
  .canClick {
    cursor: pointer;

    &:hover {
      color: #1990ff;
    }
  }

  .todayWarn {
    background-color: red;
  }

  .red {
    color: red;
  }

  .green {
    color: green;
  }
}
</style>
